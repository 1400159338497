import React from 'react';
import { Link } from 'react-router-dom';
import { formatRut } from '@fingo/lib/helpers';
import { useIsMobile } from '@fingo/lib/hooks';
import { ActionDrawerCell, ActionCell } from '@fingo/lib/components/cells';
import { Grid, Typography, Link as MuiLink } from '@mui/material';
import { Stack } from '@mui/system';
import {
  DateWithDaysDiffCell,
  TotalAmountCell,
  AvatarCell,
} from '@fingo/lib/components/dataGridCells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import CollectionActions from '@fingo/lib/components/drawer/CollectionActions';
import { filterByUniqueObjectsByKey } from '@fingo/lib/helpers/arrays';
import CompanyBlacklistComponent from '@fingo/lib/components/cells/CompanyBlacklistComponent';

const useCollectionDebtorOperationColumns = () => {
  const isMobile = useIsMobile();
  return [
    {
      field: 'name',
      headerName: 'Deudor',
      type: 'string',
      minWidth: 210,
      sortable: true,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Stack alignItems="flex-start" justifyContent="center">
          <Stack direction="row">
            <MuiLink
              component={Link}
              to={`/app/invoice-debtor/${row.company.id}/${row.id}`}
              color="primary"
              variant="body2"
              align="left"
              rel="noopener noreferrer"
              underline="hover"
            >
              {row.company.masterEntity.name}
            </MuiLink>
            <CompanyBlacklistComponent masterEntity={row.company.masterEntity} />
          </Stack>
          {!isMobile && (
            <Typography variant="subtitle1">
              {row.company.masterEntity.displayNationalIdentifier}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'executive',
      headerName: 'Ejecutivo',
      type: 'string',
      maxWidth: 100,
      sortable: true,
      filterable: false,
      flex: 1,
      valueGetter: (params) => params.row.company.executiveAssigned,
      renderCell: (params) => <AvatarCell user={params.value} />,
    },
    {
      field: 'documentsCount',
      headerName: 'N° Facturas',
      type: 'string',
      maxWidth: 100,
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter: (params) => params.row.invoices.length,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="body2">{params.value}</Typography>
        </Grid>
      ),
    },
    {
      field: 'minDateToPay',
      headerName: 'Vencimiento',
      type: 'string',
      minWidth: 140,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <DateWithDaysDiffCell
          date={row.minDateToPay}
          useColors
        />
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total en cobranza',
      type: 'string',
      sortable: false,
      filterable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        const moneyFields = params.row.invoices
          ?.map((invoice) => invoice.amountToCollect || null)
          .filter((amount) => amount?.amount);
        return <TotalAmountCell moneyFields={moneyFields} />;
      },
    },
    {
      field: 'lastAction',
      headerName: 'Última gestión',
      type: 'string',
      sortable: false,
      filterable: false,
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => {
        const actions = params.row.invoices
          ?.map((invoice) => invoice.collectionManager?.actions)
          .flat();
        const newAc = [...actions].sort((a, b) => b.id - a.id);
        return newAc[0];
      },
      renderCell: (params) => <ActionCell action={params.value} />,
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      sortable: false,
      filterable: false,
      maxWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        const actions = params.row.invoices
          ?.map((invoice) => invoice.collectionManager?.actions)
          .flat()
          .filter((action) => action !== undefined && action !== null);
        if (!actions || actions.length === 0) return [];
        const uniqueActions = filterByUniqueObjectsByKey(actions, 'id').filter(
          (action) => action?.id !== undefined,
        );
        return uniqueActions.sort((a, b) => (b?.id || 0) - (a?.id || 0));
      },
      renderCell: (params) => (
        <ActionDrawerCell
          title={params.row.company.masterEntity.name}
          subtitle={formatRut(params.row.company.masterEntity.rut)}
          actions={params.value}
          headerComponent={DrawerHeader}
          contentComponent={CollectionActions}
        />
      ),
    },
  ];
};

export default useCollectionDebtorOperationColumns;
