import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import AddReactionOutlined from '@mui/icons-material/AddReactionOutlined';
import AlarmOutlined from '@mui/icons-material/AlarmOutlined';
import Calculate from '@mui/icons-material/Calculate';
import MailOutlined from '@mui/icons-material/MailOutlined';
import NoteAltOutlined from '@mui/icons-material/NoteAltOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';

export const DEFAULT_CALCULATOR = 'DEFAULT_CALCULATOR';
export const NEW_ACTION = 'NEW_ACTION';
export const NEW_MAIL = 'NEW_MAIL';
export const COLLECTION_INFO = 'COLLECTION_INFO';
export const PROROGATION = 'PROROGATION';

const BUTTONS_BASE = {
  [DEFAULT_CALCULATOR]: {
    id: 'default-calculator-button',
    icon: Calculate,
    text: 'Calcular mora',
    props: {
      color: 'info',
    },
  },
  [NEW_ACTION]: {
    id: 'add-collection-action-button',
    icon: AddReactionOutlined,
    text: 'Nueva gestión',
  },
  [NEW_MAIL]: {
    id: 'send-collection-mail-button',
    icon: MailOutlined,
    text: 'Nuevo mail',
  },
  [COLLECTION_INFO]: {
    id: 'add-data-for-collection-button',
    icon: NoteAltOutlined,
    text: 'Info recaudación',
  },
  [PROROGATION]: {
    id: 'add-collection-prorogation-button',
    icon: AlarmOutlined,
    text: 'Prórroga',
  },
};

const COUNTRY_BUTTONS = {
  Mexico: {
    buttons: [DEFAULT_CALCULATOR, NEW_ACTION, NEW_MAIL, COLLECTION_INFO, PROROGATION],
  },
  Chile: {
    buttons: [NEW_ACTION, NEW_MAIL, COLLECTION_INFO, PROROGATION],
  },
  default: {
    buttons: [NEW_ACTION, NEW_MAIL, COLLECTION_INFO, PROROGATION],
  },
};

export const useDebtorActionButtons = (params) => {
  const { loading, selectedDocumentIds, handlers } = params;
  const isDisabled = selectedDocumentIds.length === 0 || loading;
  const country = useGetCountryFromUrl();
  const countryConfig = COUNTRY_BUTTONS[country.name] || COUNTRY_BUTTONS.default;

  const createButtonConfig = (buttonKey) => {
    const baseConfig = BUTTONS_BASE[buttonKey];
    const ButtonComponent = baseConfig.component || Button;

    return (
      <ButtonComponent
        key={baseConfig.id}
        onClick={handlers[buttonKey]}
        disabled={isDisabled}
        {...baseConfig.props}
        variant="contained"
        sx={{ height: 20, width: '100%', justifyContent: 'flex-start' }}
        startIcon={<baseConfig.icon />}
      >
        <Typography>{baseConfig.text}</Typography>
      </ButtonComponent>
    );
  };

  return {
    buttons: countryConfig.buttons.map(createButtonConfig),
  };
};
